import { refreshToken } from "./actions/auth";
import moment from "moment-timezone";

export default function jwt({ dispatch, getState }) {
  return (next) => (action) => {
    if (!action || action === "undefined") {
      return null;
    }

    // is no logged user
    let obj = getState().authReducer.user;
    if (Object.keys(obj).length === 0 && obj.constructor === Object) {
      return next(action);
    }

    if (action.type != 'USER_REQUEST_TOKEN_FILE') {
      // is logged user
      let tokenFile = getState().authReducer.token_file;
      if (typeof tokenFile != "undefined") {
        if (!tokenFile.wait) {
          //already send
          let tokenExpiration = tokenFile.exp;
          if (tokenExpiration - moment().unix() < 5) {
            let refreshTokenProm = refreshToken(dispatch, getState);
            if (typeof refreshTokenProm != "undefined")
              return refreshTokenProm.then(() => next(action));
            else next(action);
          }
        }
      }
    }

    return next(action);
  };
}

document.oncontextmenu = function() {
  return false;
};
